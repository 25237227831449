import { Pipe, PipeTransform } from '@angular/core';

import {
  ARCHIVE_EXTENSIONS,
  AUDIO_EXTENSIONS,
  DOC_EXTENSIONS,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  getFileExtension,
} from '@mp/shared/helper-links/zendesk-support-dialog/util';

@Pipe({ name: 'mpAttachmentTypeIcon' })
export class AttachmentTypeIconPipe implements PipeTransform {
  transform(filename: string): string {
    const fileExtension = getFileExtension(filename);

    switch (true) {
      case IMAGE_EXTENSIONS.includes(fileExtension):
        return 'image';
      case DOC_EXTENSIONS.includes(fileExtension):
        return 'description';
      case ARCHIVE_EXTENSIONS.includes(fileExtension):
        return 'folder_zip';
      case VIDEO_EXTENSIONS.includes(fileExtension):
        return 'video_file';
      case AUDIO_EXTENSIONS.includes(fileExtension):
        return 'audio_file';
      default:
        return 'insert_drive_file';
    }
  }
}
