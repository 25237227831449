import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { provideModuleInfo } from '@mp/shared/util';
import { TransactionManagementPermissions } from '@mp/transaction-management/core';
import {
  SupplierTransactionSettingsPermissions,
  createPlatformSupplierDetailsTabPlugin,
} from '@mp/transaction-management/supplier-settings/feature';
import { provideModulePermissions } from '@mpk/shared/data-access';
import { CLINIC_SUPPLIER_DETAILS_TAB_PLUGIN, PLATFORM_SUPPLIER_DETAILS_TAB_PLUGIN } from '@mpsm/shared/util';

const moduleName = 'TransactionManagement';

export function provideTransactionManagementFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Belegsteuerung',
    }),
    provideModulePermissions(moduleName, TransactionManagementPermissions, SupplierTransactionSettingsPermissions),
    makeEnvironmentProviders([
      {
        provide: PLATFORM_SUPPLIER_DETAILS_TAB_PLUGIN,
        multi: true,
        useFactory: createPlatformSupplierDetailsTabPlugin,
      },
      {
        provide: CLINIC_SUPPLIER_DETAILS_TAB_PLUGIN,
        multi: true,
        useFactory: createPlatformSupplierDetailsTabPlugin,
      },
    ]),
  ];
}
