import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ZendeskSupportFormDialogData } from '../../models';

import { ZendeskSupportFormDialogComponent } from './zendesk-support-form-dialog.component';

@Injectable({ providedIn: 'root' })
export class ZendeskSupportFormDialogService {
  private readonly dialog: MatDialog = inject(MatDialog);

  open(dialogData: ZendeskSupportFormDialogData): MatDialogRef<ZendeskSupportFormDialogComponent, void> {
    return this.dialog.open<ZendeskSupportFormDialogComponent, ZendeskSupportFormDialogData>(
      ZendeskSupportFormDialogComponent,
      {
        data: dialogData,
        disableClose: true,
        panelClass: 'mp-zendesk-support-form-dialog-container',
      },
    );
  }
}
