// TODO(boundaries) Fix after modernizing architecture
/* eslint-disable @nx/enforce-module-boundaries */
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import 'hammerjs';
import { provideQuillConfig } from 'ngx-quill';

import { provideQueryClient } from '@core/shared/data-access';
import {
  AbstractThemeChangesTrackingService,
  GlobalErrorHandlerService,
  IconRegistryModule,
  ThemeChangesTrackingDevService,
} from '@core/shared/util';
import { ActiveOrganizationInterceptor } from '@mp/auth/active-organization/data-access';
import { provideProfileDataAccess } from '@mp/auth/profile/data-access';
import { provideExportFeature } from '@mp/content-manager/exports/feature';
import { NewsPermissions } from '@mp/dashboard/newsfeed/feature';
import { AbstractAuthInfoProviderService } from '@mp/kernel/auth/data-access';
import { provideModulePermissions } from '@mp/kernel/permissions/data-access';
import { AppInsightsFeatureModule } from '@mp/shared/app-insights/feature';
import { InsightsOrganizationInfoService } from '@mp/shared/app-insights/util';
import { HttpErrorInterceptor, PlatformRouterSerializer, SharedModule } from '@mp/shared/data-access';
import { JobServerPermissions } from '@mp/shared/feature';
import { AbstractLinkProviderService, provideModuleInfo } from '@mp/shared/util';
import { DeploymentSettingsPermissions } from '@mp/system/deployment-settings/feature';
import { PlatformPackagingUnitsPermissions } from '@mp/system/platform-packaging-units/feature';
import { provideBusinessIntelligenceFeature } from '@mpbi/feature';
import { provideContentManagerFeature } from '@mpcm/feature';
import { provideContractManagementFeature } from '@mpctm/feature';
import { provideDocumentManagementFeature } from '@mpdm/feature';
import { provideAmor3Feature } from '@mpia/feature';
import { provideGhxFeature } from '@mpig/feature';
import { provideMediioFeature } from '@mpim/feature';
import { provideSapOdataFeature } from '@mpiso/feature';
import { provideKernelFeature } from '@mpk/feature';
import { provideMedicalShopFeature } from '@mpms/feature';
import { provideOrganizationMasterDataFeature } from '@mpomd/feature';
import { provideSupplierManagementFeature } from '@mpsm/feature';
import { provideTransactionManagementFeature } from '@mptm/feature';

import { AppComponent } from './app/app.component';
import { REDUCERS_TOKEN, reducerProvider } from './app/app.reducer';
import { routes } from './app/app.routes';
import { AppInsightsOrganizationInfoService, AuthInfoProviderService } from './app/auth';
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from './app/date-adapter';
import { AppTitleStrategy } from './app/info/app-title-strategy';
import { appInitProvider } from './app/init/app-init.service';
import { LinkProviderService } from './app/navigation';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe, 'de', localeDeExtra);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      SharedModule,
      AppInsightsFeatureModule,
      BrowserModule,
      MatNativeDateModule,
      MatSnackBarModule,
      IconRegistryModule,
    ),

    provideRouter(routes),

    provideStore(REDUCERS_TOKEN, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    provideEffects(),
    provideRouterStore({ serializer: PlatformRouterSerializer }),
    !environment.production ? provideStoreDevtools({ connectInZone: true }) : [],

    provideQuillConfig({}),

    provideProfileDataAccess(),
    appInitProvider,
    reducerProvider,
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveOrganizationInterceptor,
      multi: true,
    },
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: AbstractAuthInfoProviderService, useClass: AuthInfoProviderService },
    !environment.production
      ? { provide: AbstractThemeChangesTrackingService, useClass: ThemeChangesTrackingDevService }
      : [],
    { provide: AbstractLinkProviderService, useClass: LinkProviderService },
    { provide: InsightsOrganizationInfoService, useClass: AppInsightsOrganizationInfoService },
    provideQueryClient(),
    provideKernelFeature(),
    provideOrganizationMasterDataFeature(),
    provideBusinessIntelligenceFeature(),
    provideContentManagerFeature(),
    provideSupplierManagementFeature(),
    provideMedicalShopFeature(),
    provideContractManagementFeature(),
    provideDocumentManagementFeature(),
    provideTransactionManagementFeature(),
    provideExportFeature(),
    provideModuleInfo('Jobs', {
      displayName: 'Jobs',
    }),
    provideModulePermissions('Jobs', JobServerPermissions),
    provideModulePermissions('Kernel', NewsPermissions, DeploymentSettingsPermissions),
    provideModulePermissions('ContentManager', PlatformPackagingUnitsPermissions),
    provideAmor3Feature(),
    provideSapOdataFeature(),
    provideGhxFeature(),
    provideMediioFeature(),
  ],
}).catch((err) => console.error(err));
