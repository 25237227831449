import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { HelpCenterService } from '../services';
import { HelpCenterEffects, HelpCenterFacade, helpCenterFeatureKey, helpCenterReducer } from '../store';

export function provideHelpCenterDataAccess(): EnvironmentProviders[] {
  return [
    provideState({ name: helpCenterFeatureKey, reducer: helpCenterReducer }),
    provideEffects(HelpCenterEffects),
    makeEnvironmentProviders([HelpCenterService, HelpCenterFacade]),
  ];
}
