import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { FeedbackMailToButtonComponent, ZendeskFeedbackButtonComponent } from '@mp/shared/helper-links/feedback/ui';
import { ZendeskSupportDialogContainerComponent } from '@mp/shared/helper-links/zendesk-support-dialog/feature';

import { FeedbackButtonViewModel } from './feedback-button-view-model';
import { FeedbackButtonStore } from './feedback-button.store';

@Component({
  selector: 'mp-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrl: './feedback-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    LetDirective,

    FeedbackMailToButtonComponent,
    ZendeskFeedbackButtonComponent,
    ZendeskSupportDialogContainerComponent,
  ],
  providers: [FeedbackButtonStore],
})
export class FeedbackButtonComponent {
  @Input({ required: true }) isSmall!: boolean;

  readonly vm$: Observable<FeedbackButtonViewModel> = this.store.vm$;

  constructor(private readonly store: FeedbackButtonStore) {}
}
