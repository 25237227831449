import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { FeedbackService } from '../services';
import { FeedbackEffects, FeedbackFacade, feedbackFeatureKey, feedbackReducer } from '../store';

export function provideFeedbackDataAccess(): EnvironmentProviders[] {
  return [
    provideState({ name: feedbackFeatureKey, reducer: feedbackReducer }),
    provideEffects(FeedbackEffects),
    makeEnvironmentProviders([FeedbackService, FeedbackFacade]),
  ];
}
