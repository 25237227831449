import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DropdownComponent } from '@core/ui';
import { TicketPriority } from '@mp/shared/zendesk/domain';

@Component({
  selector: 'mp-support-message-priority-selector',
  templateUrl: './support-message-priority-selector.component.html',
  styleUrl: './support-message-priority-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatTooltipModule, DropdownComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupportMessagePrioritySelectorComponent),
      multi: true,
    },
  ],
})
export class SupportMessagePrioritySelectorComponent implements ControlValueAccessor {
  @HostBinding('class') readonly class = 'mp-support-message-priority-selector';

  protected ticketPriority!: TicketPriority;

  readonly priorityInfoTooltipText: string = `Normal: Rückmeldung innerhalb eines Werktages
  Dringend: Rückmeldung innerhalb 2 Stunden`;

  readonly prioritiesOptions: { value: TicketPriority; label: string }[] = [
    { value: TicketPriority.Normal, label: 'Normal' },
    { value: TicketPriority.Urgent, label: 'Dringend' },
  ];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onTicketPriorityChange(ticketPriority: TicketPriority | undefined): void {
    if (!ticketPriority) {
      return;
    }

    this.ticketPriority = ticketPriority;
    this.onChange(this.ticketPriority);
  }

  writeValue(ticketPriority: TicketPriority): void {
    this.ticketPriority = ticketPriority;

    this.cdr.markForCheck();
  }

  onChange = (_value: TicketPriority) => {};

  onTouched = () => {};

  registerOnChange(onChange: (value: TicketPriority) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
}
