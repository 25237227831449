import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mp-support-additional-info',
  templateUrl: './support-additional-info.component.html',
  styleUrl: './support-additional-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportAdditionalInfoComponent {
  @Input() label = '';
}
