import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-create-ticket-button',
  templateUrl: './create-ticket-button.component.html',
  styleUrl: './create-ticket-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class CreateTicketButtonComponent {
  @Output() readonly createTicketButtonClick: EventEmitter<void> = new EventEmitter<void>();

  onCreateTicketButtonClick(): void {
    this.createTicketButtonClick.emit();
  }
}
