<a
  class="help-center-direct-link-button"
  [class]="{ 'help-center-direct-link-button--small': isSmall }"
  [href]="helpCenterLink"
  target="_blank"
  rel="noopener noreferrer"
  matTooltip="Weiterleitung zum Hilfe Center"
  color="accent"
  mat-button
>
  <mat-icon class="help-center-direct-link-button__icon">support</mat-icon>
  <span class="help-center-direct-link-button__label">Hilfe Center</span>
</a>
