import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mp-zendesk-feedback-button',
  templateUrl: './zendesk-feedback-button.component.html',
  styleUrl: './zendesk-feedback-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule, MatTooltipModule],
})
export class ZendeskFeedbackButtonComponent {
  @Input({ required: true }) isSmall!: boolean;

  @Output() readonly openZendeskDialogClick: EventEmitter<void> = new EventEmitter<void>();

  openZendeskDialog(): void {
    this.openZendeskDialogClick.emit();
  }
}
