import { AsyncPipe } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  Signal,
  WritableSignal,
  computed,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationService, ToastDuration, ToastUrgency } from '@core/shared/util';
import { NavigationItemComponent, NavigationProfileComponent, ShellComponent } from '@core/ui';
import { AppConfigService } from '@mp/shared/data-access';
import { HelperLinksContainerComponent } from '@mp/shared/helper-links/feature';
import { ProfileFacade } from '@mpauth/shared/data-access';
import { Profile } from '@mpauth/shared/domain';
import { ProfileDisplayComponent } from '@mpauth/shared/ui';
import { LoginContextService } from '@mpk/shared/data-access';
import { PermissionContext } from '@mpk/shared/domain';

import { NavigationGroup, NavigationItem, NavigationStructure, navigationStructure } from '../navigation';

// NOTE: Don't add the ChangeDetection.OnPush flag here! This leads to weird behavior of the submenu
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'mp-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  imports: [
    RouterModule,
    AsyncPipe,

    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,

    ShellComponent,
    NavigationItemComponent,
    NavigationProfileComponent,
    HelperLinksContainerComponent,
    ProfileDisplayComponent,
  ],
})
export class AppLayoutComponent {
  @HostBinding('class') readonly class = 'mp-app-layout';

  hasHelperFunctions = false;

  readonly isSideNavOpened: WritableSignal<boolean> = signal<boolean>(false);

  readonly appTitle$: Observable<string> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.appTitle),
  );

  readonly showAppLogo$: Observable<boolean | undefined> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.showAppLogo),
  );

  protected readonly navigationStructure: Signal<NavigationStructure> = computed(() => {
    if (this.loginContextService.isUserLoggedIn()) {
      return this.buildNavigationStructure(this.loginContextService.permissionContext());
    }

    return [];
  });

  protected readonly profile: Signal<Profile | null> = this.profileFacade.profile;

  protected readonly profileSize: Signal<'large' | 'small'> = computed(() => {
    return this.isSideNavOpened() ? 'large' : 'small';
  });

  protected readonly hasProfileMultipleAvailableOrganizations: Signal<boolean> = computed(() => {
    return (this.profile()?.availableOrganizations?.length ?? 0) > 1;
  });

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly profileFacade: ProfileFacade,
    private readonly loginContextService: LoginContextService,
    private readonly notificationService: NotificationService,
    private readonly injector: Injector,
  ) {}

  protected logout(): void {
    this.loginContextService.scheduleLogout();

    this.notificationService.toastCustom('Du wirst ausgeloggt...', ToastDuration.Long, ToastUrgency.Info);
  }

  protected isNavigationGroup(item: NavigationGroup | NavigationItem): item is NavigationGroup {
    return 'children' in item;
  }

  protected getItemTooltipMessage(item: NavigationGroup | NavigationItem): string {
    if (this.isNavigationGroup(item) || this.isSideNavOpened()) {
      return '';
    }

    return item.label;
  }

  protected getItemChildren(item: NavigationGroup | NavigationItem): NavigationItem[] {
    return this.isNavigationGroup(item) ? item.children : [];
  }

  private buildNavigationStructure(permissionContext: PermissionContext): NavigationStructure {
    const result: NavigationStructure = [];

    for (const item of navigationStructure) {
      // Group item
      if ('children' in item) {
        const group: NavigationGroup = {
          ...item,
          children: [],
        };

        for (const child of item.children) {
          if (this.navigationItemIsVisible(child, permissionContext)) {
            group.children.push(child);
          }
        }

        if (group.children.length > 0) {
          result.push(group);
        }

        // Single item
      } else {
        if (this.navigationItemIsVisible(item, permissionContext)) {
          result.push(item);
        }
      }
    }

    return result;
  }

  private navigationItemIsVisible(navigationItem: NavigationItem, permissionContext: PermissionContext): boolean {
    if (navigationItem.isEnabled != null) {
      if (!runInInjectionContext(this.injector, navigationItem.isEnabled)) {
        return false;
      }
    }

    if (navigationItem.permissions != null && navigationItem.permissions.length > 0) {
      for (const permission of navigationItem.permissions) {
        if (permissionContext.hasPermission(permission)) {
          return true;
        }
      }
      return false;
    }

    return true;
  }
}
