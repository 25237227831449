import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
  ZENDESK_FEEDBACK_ACCESS_TOKEN_KEY,
  ZendeskOAuthScope,
  provideZendeskAuthDataAccess,
} from '@mp/shared/zendesk/auth-data-access';
import { provideZendeskDataAccess } from '@mp/shared/zendesk/data-access';
import { provideZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { ZendeskSupportDialogContainerStore } from './zendesk-support-dialog-container.store';

@Component({
  selector: 'mp-zendesk-support-dialog-container',
  templateUrl: './zendesk-support-dialog-container.component.html',
  styleUrl: './zendesk-support-dialog-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideZendeskApiDetailsService(),
    provideZendeskAuthDataAccess(ZENDESK_FEEDBACK_ACCESS_TOKEN_KEY, ZendeskOAuthScope.CreateTicket),
    provideZendeskDataAccess(),
    ZendeskSupportDialogContainerStore,
  ],
})
export class ZendeskSupportDialogContainerComponent {
  constructor(private readonly store: ZendeskSupportDialogContainerStore) {}

  openZendeskSupportDialog(): void {
    this.store.openZendeskSupportDialog();
  }
}
