import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { HelpCenterArticle } from '@mp/shared/zendesk/domain';

import { ZendeskHelpCenterArticleCardComponent } from '../zendesk-help-center-article-card/zendesk-help-center-article-card.component';

@Component({
  selector: 'mp-zendesk-help-center-articles-list',
  templateUrl: './zendesk-help-center-articles-list.component.html',
  styleUrl: './zendesk-help-center-articles-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ZendeskHelpCenterArticleCardComponent],
})
export class ZendeskHelpCenterArticlesListComponent {
  readonly zendeskHelpCenterArticles: InputSignal<HelpCenterArticle[]> = input.required<HelpCenterArticle[]>();
}
