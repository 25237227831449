import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mp-help-center-direct-link-button',
  templateUrl: './help-center-direct-link-button.component.html',
  styleUrl: './help-center-direct-link-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatTooltipModule],
})
export class HelpCenterDirectLinkButtonComponent {
  @Input({ required: true }) helpCenterLink!: string;

  @Input({ required: true }) isSmall!: boolean;
}
