import { USER_EMAIL_PLACEHOLDER, USER_FIRST_NAME_PLACEHOLDER, USER_LAST_NAME_PLACEHOLDER } from '../consts';
import { MailBodyReplacements } from '../models';

const MAIL_BODY_VARIABLES_REGEXP = new RegExp(
  `${USER_FIRST_NAME_PLACEHOLDER}|${USER_LAST_NAME_PLACEHOLDER}|${USER_EMAIL_PLACEHOLDER}`,
  'g',
);

export function replaceMailBodyVariables(emailBody: string | null, replacements: MailBodyReplacements): string {
  if (!emailBody) return '';
  return emailBody.replace(MAIL_BODY_VARIABLES_REGEXP, (match) => mailBodyVariablesReplacer(match, replacements));
}

function mailBodyVariablesReplacer(match: string, replacements: MailBodyReplacements): string {
  const { userFirstName, userLastName, userEmail } = replacements;

  switch (match) {
    case USER_FIRST_NAME_PLACEHOLDER:
      return userFirstName || '';
    case USER_LAST_NAME_PLACEHOLDER:
      return userLastName || '';
    case USER_EMAIL_PLACEHOLDER:
      return userEmail || '';
    default:
      return match;
  }
}
