import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject, map, merge, startWith, tap } from 'rxjs';

import { TicketPriority } from '@mp/shared/zendesk/domain';

import { ZendeskSupportFormDialogData } from '../../models';
import { SupportAdditionalInfoComponent } from '../support-additional-info/support-additional-info.component';
import { SupportMessageAttachmentsComponent } from '../support-message-attachments/support-message-attachments.component';
import { SupportMessagePrioritySelectorComponent } from '../support-message-priority-selector/support-message-priority-selector.component';

@Component({
  selector: 'mp-zendesk-support-form-dialog',
  templateUrl: './zendesk-support-form-dialog.component.html',
  styleUrl: './zendesk-support-form-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    LetDirective,

    MatButtonModule,
    MatDialogTitle,
    MatDialogClose,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,

    SupportMessagePrioritySelectorComponent,
    SupportMessageAttachmentsComponent,
    SupportAdditionalInfoComponent,
  ],
})
export class ZendeskSupportFormDialogComponent {
  readonly priorityControl: FormControl<TicketPriority> = this.fb.control(TicketPriority.Normal, Validators.required);
  readonly subjectControl: FormControl<string> = this.fb.control('', Validators.required);
  readonly bodyControl: FormControl<string> = this.fb.control('', Validators.required);
  readonly attachmentsControl: FormControl<File[]> = this.fb.control([]);

  readonly zendeskSupportForm = this.fb.group({
    priority: this.priorityControl,
    subject: this.subjectControl,
    body: this.bodyControl,
    attachments: this.attachmentsControl,
  });

  private readonly MAX_FILE_SIZE_IN_MB: number = 50;

  private readonly MB_MULTIPLIER: number = 1024 * 1024;

  readonly MAX_ATTACHMENTS_NUMBER: number = 5;

  readonly MAX_FILE_SIZE: number = this.MAX_FILE_SIZE_IN_MB * this.MB_MULTIPLIER;

  readonly attachmentsInfoTooltipText: string = `
  Maximale Anzahl an erlaubten Anhängen: ${this.MAX_ATTACHMENTS_NUMBER}.
  Die jeweilige Datei darf nicht größer sein als ${this.MAX_FILE_SIZE_IN_MB} MB.
  `;

  private readonly _ticketSend$: Subject<void> = new Subject<void>();

  readonly ticketSendInProgress$: Observable<boolean> = merge(
    this._ticketSend$.asObservable().pipe(map(() => true)),
    this.data.ticketCreationError$.pipe(map(() => false)),
  ).pipe(startWith(false));

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ZendeskSupportFormDialogData,
    private readonly dialogRef: MatDialogRef<ZendeskSupportFormDialogComponent>,
    private readonly fb: NonNullableFormBuilder,
  ) {
    this.data.ticketCreationSuccess$
      .pipe(
        tap(() => this.dialogRef.close()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  onZendeskSupportMessageSend(): void {
    this._ticketSend$.next();
    this.data.sendTicket(this.zendeskSupportForm.getRawValue());
  }
}
