import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { filterUndefined } from '@core/shared/util';
import { BasicFlyoutComponent, FlyoutAnimation, FlyoutComponent, OVERLAY_DATA, SpinnerComponent } from '@core/ui';
import { HelpCenterArticle } from '@mp/shared/zendesk/domain';
import { HELP_CENTER_ARTICLES_PAGE_SIZE } from '@mp/shared/zendesk/util';

import { ZendeskHelpCenterInfoFlyoutIntegrationData } from '../../models';
import { ZendeskHelpCenterArticlesListComponent } from '../zendesk-help-center-articles-list/zendesk-help-center-articles-list.component';
import { ZendeskHelpCenterFlyoutActionsComponent } from '../zendesk-help-center-flyout-actions/zendesk-help-center-flyout-actions.component';

@Component({
  selector: 'mp-zendesk-help-center-info-flyout',
  templateUrl: './zendesk-help-center-info-flyout.component.html',
  styleUrl: './zendesk-help-center-info-flyout.component.scss',
  animations: FlyoutAnimation.Named('openClose'),
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    LetDirective,

    MatButtonModule,
    MatIconModule,

    BasicFlyoutComponent,
    ZendeskHelpCenterFlyoutActionsComponent,
    SpinnerComponent,
    ZendeskHelpCenterArticlesListComponent,
  ],
})
export class ZendeskHelpCenterInfoFlyoutComponent implements FlyoutComponent<undefined> {
  @HostBinding('@openClose') animationState: FlyoutAnimation.State = 'open';

  readonly helpCenterLink: string | null = this.data.helpCenterLink;

  readonly helpCenterWithArticlesLabelLink = this.data.helpCenterWithArticlesLabelLink;

  readonly showCreateTicketButton: boolean = this.data.showCreateTicketButton;

  readonly createTicketButtonTemplate?: TemplateRef<unknown> = this.data.createTicketButtonTemplate;

  readonly maximumArticlesCount: number = HELP_CENTER_ARTICLES_PAGE_SIZE;

  readonly zendeskHelpCenterArticles$: Observable<HelpCenterArticle[]> =
    this.data.zendeskHelpCenterArticles$.pipe(filterUndefined());

  readonly zendeskHelpCenterArticlesCount$: Observable<number> = this.data.zendeskHelpCenterArticlesCount$;

  private readonly _afterClosed$: Subject<void> = new Subject();

  readonly afterClosed$: Observable<void> = this._afterClosed$.pipe(delay(FlyoutAnimation.AnimationDuration));

  constructor(@Inject(OVERLAY_DATA) private readonly data: ZendeskHelpCenterInfoFlyoutIntegrationData) {}

  close(): void {
    this._afterClosed$.next();
    this._afterClosed$.complete();
  }
}
