import { permissions } from '@mpk/shared/domain';

/**
 * Transaction management can be used
 */
enum TransactionManagementPermissionsEnum {
  None = 0,

  /**
   * Transaction management can be used
   */
  UseTransactionManagement = 1,
}

export const TransactionManagementPermissions = permissions(TransactionManagementPermissionsEnum, {
  featureId: 'TransactionManagement',
  featureDisplayName: 'Belegmonitor',
  displayNames: {
    UseTransactionManagement: 'Belegmonitor benutzen',
  },
});
