import { EnvironmentInjector, createEnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type { PlatformSupplierDetailsTabPlugin, PlatformSupplierDetailsTabPluginInput } from '@mpsm/shared/util';

import { SupplierTransactionSettingsPermissions } from '../permissions';

export function createPlatformSupplierDetailsTabPlugin(): PlatformSupplierDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Belegsteuerung',
    isEnabled: loginContextService.permission(SupplierTransactionSettingsPermissions.ViewSupplierTransactionSettings),
    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<PlatformSupplierDetailsTabPluginInput>>> {
      const { SupplierTransactionSettingsComponent } = await import('../components');
      const { provideSupplierTransactionSettingsDataAccess } = await import(
        '@mp/transaction-management/supplier-settings/data-access'
      );

      return {
        component: SupplierTransactionSettingsComponent,
        environmentInjector: createEnvironmentInjector(provideSupplierTransactionSettingsDataAccess(), parentInjector),
      };
    },
  };
}
