import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs/operators';

import { NotificationService } from '@core/shared/util';
import { ActiveOrganizationService } from '@mp/auth/active-organization/data-access';

import { ProfileService } from '../services';

import { ProfileActions } from './profile.actions';

@Injectable()
export class ProfileEffects {
  readonly notificationService = inject(NotificationService);
  readonly actions$ = inject(Actions);
  readonly profileService = inject(ProfileService);
  readonly activeOrganizationService = inject(ActiveOrganizationService);

  fetchProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.fetchProfile),
      fetch({
        run: () => {
          return this.profileService
            .fetchProfile()
            .pipe(map((profile) => ProfileActions.fetchProfileSuccess({ profile })));
        },
        onError: () => {
          return ProfileActions.fetchProfileError();
        },
      }),
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.updateProfile),
      fetch({
        run: ({ dto }) => {
          return this.profileService.updateProfile(dto).pipe(
            map((profile) => ProfileActions.updateProfileSuccess({ profile })),
            tap(() => this.notificationService.toastSuccess('Das Profil wurde erfolgreich aktualisiert.')),
          );
        },
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Aktualisieren des Profils.');
          return ProfileActions.updateProfileError();
        },
      }),
    );
  });

  setActiveOrganization$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProfileActions.fetchProfileSuccess),
        tap(({ profile }) => {
          if (profile.activeOrganization) {
            this.activeOrganizationService.setActiveOrganizationId(profile.activeOrganization.organizationId);
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
