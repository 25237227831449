import { ChangeDetectionStrategy, Component, Input, OutputRef } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { LetDirective } from '@ngrx/component';
import { Observable, map } from 'rxjs';

import { FeedbackButtonComponent } from '@mp/shared/helper-links/feedback/feature';
import { HelpCenterButtonComponent } from '@mp/shared/helper-links/help-center/feature';

import { HelperLinksContainerViewModel } from './helper-links-container-view-model';
import { HelperLinksContainerStore } from './helper-links-container.store';

@Component({
  selector: 'mp-helper-links-container',
  templateUrl: './helper-links-container.component.html',
  styleUrl: './helper-links-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, HelpCenterButtonComponent, FeedbackButtonComponent],
  providers: [HelperLinksContainerStore],
})
export class HelperLinksContainerComponent {
  @Input() isSmall = false;

  readonly vm$: Observable<HelperLinksContainerViewModel> = this.store.vm$;

  readonly hasHelperFunctions$: Observable<boolean> = this.store.vm$.pipe(
    map((vm) => vm.isFeedbackEnabled || vm.isHelpCenterEnabled),
  );

  readonly hasHelperFunctions: OutputRef<boolean> = outputFromObservable(this.hasHelperFunctions$);

  constructor(private readonly store: HelperLinksContainerStore) {
    this.store.fetchFeedbackSettings();
    this.store.fetchHelpCenterSettings();
  }
}
