import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ProfileEffects, ProfileFacade, profileFeatureKey, profileReducer } from '../store';

export function provideProfileDataAccess(): EnvironmentProviders[] {
  return [
    provideState({ name: profileFeatureKey, reducer: profileReducer }),
    provideEffects(ProfileEffects),
    makeEnvironmentProviders([ProfileFacade]),
  ];
}
